* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

a {
    list-style: none;
    text-decoration: none;
}